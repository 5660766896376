<template>
  <div class="col-12 col-lg-9 mt-5">
            <div class="headbar-new">
          <div class="counter-new d-none d-none">
            <span> <b>ENDING IN: </b> </span>
            <span class='counter-items'>Hours: {{hours}}</span> -
            <span class='counter-items'>Minutes: {{minutes}}</span> -
            <span class='counter-items'>Seconds: {{seconds}}</span> 
        </div>
        <div class="d-none">
            <p class="text-center mb-0"> <b>ENDING IN</b> </p>
            <p class='text-center t-bold'>
                <span class="d-inline-block w-25 px-3">
                     <span class='text-center'>Days</span>
                     <br> 
                     <span class='text-center'>
                          {{days}}
                     </span>
                </span>
                <span class="d-inline-block w-25 px-3">
                     <span>Hours</span>
                     <br> 
                     <span>
                          {{hours}}
                     </span>
                </span>
                <span class="d-inline-block w-25 px-3">
                     <span>Minutes</span>
                     <br> 
                     <span>
                          {{minutes}}
                     </span>
                </span>
                 <span class="d-inline-block w-25 px-3">
                     <span>Seconds</span>
                     <br> 
                     <span>
                          {{seconds}}
                     </span>
                </span>
            </p>
        </div>
          </div>
        </div>
</template>

<script>
export default {
    props:['expired','days','hours','minutes','seconds']
}
</script>

<style>

</style>